import * as React from 'react';
import { GameInfo } from '../../pages/index';
import Button from '../button/component';
import './styles.scss';

interface Props {
  info: GameInfo;
}

const GameItem: React.FunctionComponent<Props> = ({ info }) => {
  return (
    <div
      className="game-item"
    >
      <div className="game-item__info">
        <div>
          <h2>
            {info.name}
          </h2>
        </div>
        <div
          className="game-item__description"
        >
          <p>
            {info.description}
          </p>
        </div>
        {getPlayButton(info.url)}
        <div
          className="game-item__additional-info"
        >
          {getGameCredits(info.credits)}
          <p>
            Built with: {info.languages}
          </p>
        </div>
      </div>
      <div className="game-item__gif">
        {info.gif}
      </div>
    </div>
  );
};

function getPlayButton(url: string | null | undefined) {
  if (url) {
    return (
      <div className="game-item__play-button">
        <Button
          name="Play"
          link={url}
          openNewTab={true}
        />
      </div>
    );
  }
}

function getGameCredits(credits: string | null | undefined) {
  if (credits) {
    return (
      <div className="game-item__credits">
        <p>
          {credits}
        </p>
      </div>
    );
  }
}

export default GameItem;
