import { graphql } from 'gatsby';
import * as React from 'react';
import GameItem from '../components/game-item/component';
import GrimGIF from '../components/game-item/gif/grim.gif';
import LearningTheoriesGIF from '../components/game-item/gif/learning-theories-game.gif';
import NoNGIF from '../components/game-item/gif/nails-on-ninth-salon.gif';
import NothingToSeeHereGIF from '../components/game-item/gif/nothing-to-see-here.gif';
import TetrominoGIF from '../components/game-item/gif/tetromino.gif';
import Layout from '../components/layout/component';

interface ProjectsPageProps {
  data: {
    allGamesJson: {
      edges: GameNode[];
    };
  };
}

interface GameNode {
  node: {
    name: string;
    description: string;
    languages: string;
    credits: string | null;
    url: string | null;
  };
}

export interface GameInfo {
  name: string;
  description: string;
  gif: JSX.Element | undefined;
  languages: string;
  credits: string | null | undefined;
  url: string | null | undefined;
}

export const gamesPageQuery = graphql`
  query GamesQuery {
    allGamesJson {
      edges {
        node {
          name
          description
          languages
          credits
          url
        }
      }
    }
  }
`;

const ProjectsPage: React.FunctionComponent<ProjectsPageProps> = ({ data }) => {
  const games: GameInfo[] = data.allGamesJson.edges.map((gameNode: GameNode) => {
    const gameGIF = getGIF(gameNode.node.name);

    return {
      credits: gameNode.node.credits,
      description: gameNode.node.description,
      gif: gameGIF,
      languages: gameNode.node.languages,
      name: gameNode.node.name,
      url: gameNode.node.url,
    };
  });

  const projectsTabTitle = 'Projects';
  const projectsPageTitle = 'Projects';

  return (
    <Layout
      title={projectsPageTitle}
      tabTitle={projectsTabTitle}
    >
      {getGamesList(games)}
    </Layout>
  );
};

function getGIF(name: string): JSX.Element | undefined {
  if (typeof window !== `undefined`) {
    const GifPlayer = require('react-gif-player');

    switch (name.toUpperCase()) {
      case ('GRIM'):
        return <GifPlayer gif={GrimGIF} />;
      case ('TETROMINO'):
        return <GifPlayer gif={TetrominoGIF} />;
      case ('NAILS ON NINTH'):
        return <GifPlayer gif={NoNGIF} />;
      case ('NOTHING TO SEE HERE'):
        return <GifPlayer gif={NothingToSeeHereGIF} />;
      case ('LEARNING THEORIES GAME'):
        return <GifPlayer gif={LearningTheoriesGIF} />;
      default:
        return undefined;
    }
  }
}

function getGamesList(games: GameInfo[]): JSX.Element[] {
  const gameObjects = games.map((g: GameInfo) => {
    return (
      <GameItem
        key={g.name}
        info={g}
      />
    );
  });
  return gameObjects;
}

export default ProjectsPage;
